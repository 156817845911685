import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { GatsbyPage, ITeammate } from "src/types"
import Layout from "src/layout"
import Image from "src/components/Image"
import { SIZES, COLORS, BREAKPOINTS } from "src/constants"
import {
  Section,
  Subtitle,
  Title,
  Content,
  SectionTitle,
  Text,
  RowSection,
  Column,
  Answer,
  Illustration,
} from "src/components/styled"
import Gallery from "src/components/Gallery"
import { Splash, Parallax } from "src/components/Parallax"
import { scrollToRef } from "src/helpers/window"

const Caption = styled.div`
  font-size: ${SIZES.normal};
  font-weight: bold;
  margin: 8px 0 0;
`
const SubCaption = styled.div`
  font-size: ${SIZES.normal};
  font-style: italic;
  margin: 5px 0 0;
`
const Photo = styled(Image)`
  background-color: ${COLORS.placeholder};
  .empty {
    padding-bottom: 100%;
  }
`
const Name = styled.figcaption`
  font-size: ${SIZES.medium} !important;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    font-size: ${SIZES.small} !important;
  }
`

const TeamPage: GatsbyPage = ({ data }) => {
  return (
    <Layout title="Équipe">
      <Splash>
        <Parallax desktop={data.splashDesktop.childImageSharp} mobile={data.splashMobile.childImageSharp} />
      </Splash>
      <Content>
        <Section>
          <Subtitle>L’ÉQUIPE DES ATELIERS</Subtitle>
          <Title ref={scrollToRef}>
            <span>
              Nous sommes designer, ébéniste ou architecte et le temps d’un atelier, nos savoir-faire sont mis à votre
              service pour accompagner vos projets.
            </span>
          </Title>
          <p>&nbsp;</p>
        </Section>
        <RowSection>
          <Column>
            <SectionTitle>Les fondateurs</SectionTitle>
            <Answer>
              Un projet développé par l’agence de design Premices and co, spécialisée en économie circulaire
            </Answer>
            <Text>
              Premices and co est une agence de design et d’architecture intérieure. Elle privilégie une conception
              responsable basée sur une valorisation de ce qui est déjà là.
              <br />
              L’agence rassemble les designers Camille Chardayre, Amandine Langlois et Jérémie Triaire, tous les trois
              diplômés de l’école Boulle. Ses réalisations sont variées : réhabilitation d’espaces privés ou publics,
              conception de matériaux ou de mobiliers issus du recyclage… L’agence collabore avec des entreprises, des
              institutions, des collectivités et des particuliers.
            </Text>
            <Text>
              <a href="https://premicesandco.com">premicesandco.com</a>
            </Text>
          </Column>
          <Column>
            <Illustration file={data.team.childImageSharp} />
            <Caption>Premices and co</Caption>
            <SubCaption>Camille Chardayre, Jérémie Triaire et Amandine Langlois</SubCaption>
          </Column>
        </RowSection>
        <Section>
          <SectionTitle>L’équipe d’encadrement</SectionTitle>
          <Gallery columns={3}>
            {data.teammates.nodes.map(({ id, photo, name, job }: ITeammate) => (
              <figure key={id}>
                <Photo file={photo} />
                <Name>{name}</Name>
                <div>{job}</div>
              </figure>
            ))}
          </Gallery>
        </Section>
      </Content>
    </Layout>
  )
}

export default TeamPage

export const query = graphql`
  query {
    splashDesktop: file(relativePath: { eq: "team/splash-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    splashMobile: file(relativePath: { eq: "team/splash-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    team: file(relativePath: { eq: "team/portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    teammates: allContentfulTeammate(sort: { fields: [position], order: ASC }) {
      nodes {
        id: contentful_id
        name
        job
        photo {
          fluid(maxWidth: 350) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
